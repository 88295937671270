.App {
  font-family: "Roboto" !important;
}

/*  SCROLLBAR  */

/* width */
/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background-color: transparent;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #888 ;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background-color: #90a4ae ;
} */




.errors_paper{
  color: white !important;
  background-color: #B22132 !important;
  text-align: left;
  padding: 10px;
  width: fit-content;
}

.responsive_table {
  width: 100%;
  overflow-x: auto;
}

.top10{
  margin-top: 10px;
}

.left5{
  margin-left: 5px !important;
}

.center{
  text-align: center !important;
}

.side_padding8{
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.fontWeight200{
  font-weight: 200 !important;
}

.margin10{
  margin: 10px;
}

.basic_container {
  padding: 3%;
  margin: 4%;
  border: 2px solid #B22132;
}

/* ADMIN SIDE  */
.admin_container {
  padding: 3%;
  margin: 4%;
  margin-top: 100px;
  border: 2px solid #B22132;
}

.admin_loader {
  margin-top: 200px;
}
