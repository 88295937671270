.Exoneration-sub-title {
    margin-top: 10px;
    margin-bottom: 10px;
}

.Exoneration-result-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.Exoneration-tab_container {
    border: solid 1px black;
    width: 80%;
}

.Exoneration-row-container {
    border: solid 1px black;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}